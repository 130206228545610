* {
  box-sizing: border-box;
}
.color1{
  color: var(--color-text);
}
.color2{
  color: var(--color-primary);
}

@font-face {
  font-family: "DFKAI71";
  src: url("./fonts/DFKAI71.ttf");
}
@font-face {
  font-family: "DFMW5";
  src: url("./fonts/DFMW5.ttf");
}
@font-face {
  font-family: "Myriad-Pro";
  src: url("./fonts/Myriad-Pro.otf");
}
@font-face {
  font-family: "Cleargothic";
  src: url("./fonts/cleargothic-serial-bold.ttf");
}
@font-face {
  font-family: "china2";
  src: url("./fonts/china2.ttf");
}
@font-face {
  font-family: "Kaiu";
  src: url("./fonts/kaiu.ttf");
}
@font-face {
  font-family: "UMT-centur";
  src: url("./fonts/UTM-Centur.ttf");
}
@font-face {
  font-family: "UMT-Alexander";
  src: url("./fonts/UTM-Alexander.ttf");
}

@font-face {
  font-family: "Shippori";
  src: url("./fonts/Shippori.ttf");
}
.font-Shippori {
  font-family: "Shippori", serif;  font-weight: 700;

}
/* DFKAI71 */
.font-china2 {
  font-family: "china2", serif;  font-weight: 700;

}
.font-DFKAI71 {
  font-family: "DFKAI71", serif;  font-weight: 700;

}
.font-DFMW5 {
  font-family: "Myriad-Pro", serif;  font-weight: 700;

}
.font-Myriad {
  font-family: "Myriad-Pro", serif;
  font-weight: 700;
}
.font-Alexander {
  font-family: "UMT-Alexander", serif;  font-weight: 700;

}
.font-UMT {
  font-family: "UMT-centur", serif;  font-weight: 700;

}
.font-kaiu {
  font-family: "Kaiu", serif;  font-weight: 700;

}
.font-cleargothic {
  font-family: "Cleargothic", serif;  font-weight: 700;

}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App {
  left: 0;
  padding: 0;
  top: 0;
  width: 100%;
}
.modal-container {
  width: 1600px;
  height: 800px;
  margin: 0;
  padding: 0;
}
#link-domain {
  margin-left: 50px;
  margin-top: 5px;
}
.to-show-print {
  display: none;
}
#web-name {
  color: #32518f;
  font-weight: "bolder";
}
#slogan-web {
  color: #656262;
}
.text-center {
  text-align: center;
}
@page {
  size: A4;
  margin: 0;
}
@media print {
  .to-hide-print {
    display: none;
  }
  .to-show-print {
    display: inline;
    margin-left: 50px;
    position: absolute;
  }
  #page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    /* page-break-after: always; */
  }
}

#headerLogo {
  flex-direction: row;
}

#LoadingScreen {
  width: 100%;
  /* background-color: red; */
}

#LoadingImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  margin-bottom: auto;
  width: 60%;
}

#header {
  height: 56px;
  border: 1px solid #1667af;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

div.button {
  justify-self: center;
  align-items: center;
  width: 45px;
  height: 20px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

body a:hover,
body a.active {
  color: #c1282d;
}
body #uhchat {
  right: 10px !important;
  left: inherit !important;
}
#page-wrapper,
#page {
  float: left;
  width: 100%;
}
.bx-wrapper img {
  display: block;
  max-width: 100%;
  width: 100%;
}
#main-wrapper,
#main {
  float: left;
  width: 100%;
}
.open-menu {
  display: none;
}
.contentner {
  margin: 0 auto;
  width: 1110px;
}
.header {
  background: rgba(0, 0, 0, 0)
    url("http://www.phongthuytuongminh.com/sites/all/themes/idol_themes/images/bg-footer.jpg")
    repeat scroll center center;
  float: left;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 999;
}
.close_quick-link {
  font-size: 22px;
  position: absolute;
  right: 0;
  top: -20px;
}
.menu-site {
  background: rgba(0, 0, 0, 0)
    url("http://www.phongthuytuongminh.com/sites/all/themes/idol_themes/images/bg-menu.jpg")
    repeat-x scroll 0 0;
  float: left;
  height: 33px;
  width: 100%;
}
.logo-on {
  float: left;
  height: 110px;
  position: relative;
  width: 100%;
}
.logo-site {
  float: left;
}
.right-h {
  float: right;
  margin-top: 35px;
}
.hotline {
  color: #fff;
  display: inline-block;
  float: left;
  font-size: 22px;
  font-weight: 600;
  margin-top: 4px;
}
.hotline a {
  color: #fff;
}
.hotline .fa.fa-phone {
  border-right: 1px solid #c1282e;
  float: left;
  font-size: 26px;
  margin-right: 6px;
  padding-right: 6px;
}
.search-form {
  display: inline-block;
  float: left;
  margin-left: 30px;
}
form.form-tim-kiem {
  background: #fff none repeat scroll 0 0;
  display: none;
  float: left;
  margin-top: 3px;
}
/*.ads-slide-show .ads-items {
    float: left;
    height: 100vh;
    position: relative;
    width: 100vw;
}
.ads-slide-show .ads-items img {
    left: 50%;
    min-height: 100vh;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}*/
form.form-tim-kiem .form-text {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  padding: 4px;
  width: auto;
}
.form-tim-kiem .form-submit {
  background: rgba(0, 0, 0, 0)
    url("http://www.phongthuytuongminh.com/sites/all/themes/idol_themes/images/btn-search24.png")
    no-repeat scroll center center !important;
  border: medium none;
  border-radius: 5px;
  cursor: pointer;
  float: right;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  height: 28px;
  line-height: 22px;
  padding: 3px 13px;
  text-align: center;
  text-transform: uppercase;
}
.btn-show-search {
  border: 1px solid #c1282e;
  color: #fff;
  float: right;
  font-size: 19px;
  padding: 4px 8px;
  cursor: pointer;
}
.banner-top,
.ads-slide-show {
  float: left;
  width: 100%;
}
ul.nice-menu li {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  margin: 0;
  padding-left: 0;
}
ul.nice-menu li a {
  color: #fff;
  float: left;
  font-size: 13px;
  font-weight: bold;
  padding: 4px 19px;
  text-transform: uppercase;
}
ul.nice-menu li.first a {
  padding: 4px 8px;
  font-size: 20px;
}
ul.nice-menu li a.active {
  background: #c1282e;
}
ul.nice-menu-down li.menuparent {
  background: none;
}
ul.nice-menu li:hover,
ul.nice-menu-down li.menuparent:hover {
  background: #c1282e;
}
ul.nice-menu-down .menuparent a {
  padding-right: 19px;
}
ul.nice-menu ul,
#header-region ul.nice-menu ul {
  background: #c1282e none repeat scroll 0 0;
  border: medium none;
  top: 32px;
  width: 230px;
}
ul.nice-menu ul li {
  float: left;
  width: 100%;
}
ul.nice-menu-down li ul li ul {
  left: 230px;
  top: 0;
}
ul.nice-menu ul li a {
  display: block;
  text-transform: none;
  width: 192px;
}
ul.nice-menu ul li a:hover,
ul.nice-menu ul li a.active {
  background: #181c23;
  color: #fff;
}
.tabs {
  float: left;
  width: 100%;
}
.ads-items {
  float: left;
  position: relative;
  width: 100%;
}
.ads-slide-show .body-only {
  bottom: 0;
  color: #fff;
  font-size: 20px;
  position: absolute;
  width: 100%;
  z-index: 999;
}
.ads-slide-show .body-only .content-body {
  margin: 0 auto;
  width: 1110px;
}
.ads-slide-show .body-only .content-body h2 {
  border-bottom: 1px solid #ff0008;
  color: #ff0008;
  display: inline-block;
  margin-bottom: 0;
  margin-top: 0;
  padding-bottom: 10px;
  width: auto;
}
p {
  margin-top: 5px;
}
.gioi-thieu-home {
  background: #ecedef none repeat scroll 0 0;
  float: left;
  text-align: center;
  width: 100%;
  padding: 15px 0;
  font-size: 14px;
}
.form-cong-cu {
  float: left;
  margin-bottom: 30px;
  text-align: center;
  width: 100%;
}
.form-cong-cu .form-items {
  float: left;
  width: 100%;
}
.form-cong-cu .form-items .form-item {
  display: inline-block;
  margin-bottom: 1em;
  margin-top: 0;
}
.form-cong-cu .form-submit {
  background: #337ab7 none repeat scroll 0 0;
  border: medium none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 10px 30px;
}
.form-cong-cu .form-submit:hover {
  background: #c1282e none repeat scroll 0 0;
}
h2.title {
  font-size: 26px;
  font-weight: 700;
  margin: 15px 0;
  text-transform: uppercase;
  color: #2c3946;
  line-height: 30px;
}
.dang-ky-ban-tin h2.title {
  color: #fff;
}
h2.title.red {
  color: #c1282d;
}
.icon-black {
  background: rgba(0, 0, 0, 0)
    url("http://www.phongthuytuongminh.com/sites/all/themes/idol_themes/images/icon-h2-black.png")
    no-repeat scroll center 0;
  display: block;
  height: 17px;
  margin-bottom: 20px;
  width: 100%;
}
.block-home {
  background: rgba(0, 0, 0, 0)
    url("http://www.phongthuytuongminh.com/sites/all/themes/idol_themes/images/bg-intro.png")
    no-repeat scroll 0 center;
  float: left;
  width: 100%;
}
.link-more a {
  background: #c1282d none repeat scroll 0 0;
  border-radius: 4px;
  color: #fff;
  display: inline-block;
  font-size: 13px;
  padding: 2px 15px;
  text-transform: uppercase;
}
.link-more a:hover {
  color: #fff;
  opacity: 0.7;
}
.header-groups {
  float: left;
  position: relative;
  width: 100%;
}
#breadcrumb {
  background: rgba(0, 0, 0, 0.5) none repeat scroll 0 0;
  bottom: 0;
  color: #fff;
  height: 65px;
  position: absolute;
  width: 100%;
  z-index: 9999;
}
h1#page-title {
  float: left;
  font-size: 26px;
  margin: 0;
  padding: 21px 0 20px;
  text-transform: uppercase;
}
h1#page-title.bg-black {
  background: #14171d none repeat scroll 0 0;
  padding-left: 20px;
  padding-right: 20px;
  width: 262px;
}
.breadcrumb {
  float: right;
  margin-top: 21px;
  padding-bottom: 0;
  text-transform: uppercase;
}
.breadcrumb a {
  color: #fff;
}
#main-wrapper {
  margin-top: 0px;
}
.node-master .field.field-name-field-image.field-type-image.field-label-hidden {
  float: right;
  margin-left: 30px;
  width: 350px;
}
.node-master
  .field.field-name-body.field-type-text-with-summary.field-label-hidden {
  text-align: justify;
}

.node-title.red {
  background: rgba(0, 0, 0, 0)
    url("http://www.phongthuytuongminh.com/sites/all/themes/idol_themes/images/icon-h2-red.png")
    no-repeat scroll 0 bottom;
  display: block;
  margin-bottom: 26px;
  margin-top: 0;
  padding-bottom: 36px;
  text-align: left;
  text-transform: none;
  width: 100%;
}
#page-title-int {
  background: rgba(0, 0, 0, 0)
    url("http://www.phongthuytuongminh.com/sites/all/themes/idol_themes/images/icon-h2-red.png")
    no-repeat scroll center bottom;
  display: block;
  margin-bottom: 26px;
  padding-bottom: 36px;
  text-align: center;
  text-transform: none;
  width: 100%;
}
.master-home {
  background: rgba(0, 0, 0, 0)
    url("http://www.phongthuytuongminh.com/sites/all/themes/idol_themes/images/bg-master-1.jpg")
    no-repeat scroll center center;
  float: left;
  height: 498px;
  width: 100%;
  margin-bottom: 30px;
}
.block-master-home {
  float: left;
  width: 100%;
}
.block-master-home .ads-items {
  float: left;
  height: 498px;
  position: relative;
  width: 100%;
}
.block-master-home .ads-items .image {
  bottom: 0;
  position: absolute;
  width: 35%;
}
.block-master-home .ads-items .inform {
  display: table;
  float: right;
  height: 498px;
  width: 50%;
  color: #fff;
}
.display-row {
  display: table-row;
  height: 100%;
}
.display-col {
  display: table-cell;
  height: 100%;
  vertical-align: middle;
}
.block-master-home .ads-items .inform h1.title {
  color: #e8e8e8;
  text-transform: uppercase;
}
.bxslidermaster,
.bxslidermaster li {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}
.block-master-home .ads-items .inform h2.title {
  color: #f00;
  font-size: 34px;
  width: 420px;
  line-height: 40px;
}
.linered {
  background: #c32f33 none repeat scroll 0 0;
  display: block;
  height: 1px;
  width: 80px;
}
.block-master-home .link-more {
  text-align: right;
}
.header-link {
  float: left;
  position: relative;
  width: 100%;
  text-align: center;
}
.icon-red {
  background: rgba(0, 0, 0, 0)
    url("http://www.phongthuytuongminh.com/sites/all/themes/idol_themes/images/icon-h2-red.png")
    no-repeat scroll center 0;
  display: block;
  height: 17px;
  margin-bottom: 20px;
  width: 100%;
}
.link-page {
  border: 1px solid #ccc;
  font-size: 13px;
  padding: 3px 10px;
  position: absolute;
  right: 0;
  top: 15px;
}
.items-tuvan {
  float: none;
  margin: 0 auto;
  width: 265px;
}
.items-tuvan .title {
  font-size: 15px;
  margin: 6px 0;
  text-align: center;
}
.items-tuvan .des {
  padding: 0 15px;
  text-align: center;
  color: #515151;
}
.tu-van-home {
  float: left;
  margin-bottom: 30px;
  width: 100%;
}
.owl-carousel {
  float: left;
}
.dang-ky-ban-tin {
  background: #b52025 none repeat scroll 0 0;
  float: left;
  padding: 20px 0;
  text-align: center;
  width: 100%;
  color: #fff;
}
.simplenews-subscribe {
  margin: 0 auto;
  width: 615px;
}
.simplenews-subscribe .form-item.form-type-textfield.form-item-mail {
  /*display: inline-block;*/
  /*float: left;*/
  margin: 0;
  width: 100%;
}
.simplenews-subscribe
  .form-item.form-type-textfield.form-item-mail
  input.form-text {
  background: #fff none repeat scroll 0 0;
  border: medium none;
  display: block;
  margin: auto;
  padding: 9px 60px;
  width: 304px;
  box-sizing: border-box;
}
.simplenews-subscribe .form-submit {
  background: rgba(0, 0, 0, 0)
    url("http://www.phongthuytuongminh.com/sites/all/themes/idol_themes/images/btn-gui.jpg")
    no-repeat scroll center center;
  border: medium none;
  cursor: pointer;
  display: inline-block;
  height: 35px;
  margin-left: 10px;
  width: 119px;
}
.coppyright {
  background: #14171d none repeat scroll 0 0;
  color: #fff;
  float: left;
  padding-top: 5px;
  text-align: center;
  width: 100%;
}
.footer-site {
  /* background: #14171d none repeat scroll 0 0; */
  background: rgba(0, 0, 0, 0) url("../src/image/bg-footer.jpg") repeat scroll
    center center rgba(0, 0, 0, 0);
  /* url('https://img.freepik.com/free-vector/luxury-elegant-black-seamless-pattern_98292-5961.jpg?size=338&ext=jpg')
    repeat scroll center center; */
  color: #fff;
  /* float: left; */
  width: 100%;
}
.footer-site .address {
  float: left;
  margin-left: 20px;
  margin-top: 24px;
  width: 520px;
}
.social {
  float: right;
  margin-top: 24px;
}
.social a {
  border: 1px solid;
  border-radius: 50%;
  color: #eee;
  display: inline-block;
  font-size: 17px;
  height: 40px;
  margin-left: 7px;
  text-align: center;
  width: 40px;
}
.footer-bottom {
  float: left;
  margin: 10px 0;
  width: 100%;
}
.menu-footer-b {
  float: left;
  width: 885px;
}
.menu-footer-b ul.menu {
  border: medium none;
  float: left;
  list-style: outside none none;
  margin: 0;
  padding: 0;
  text-align: left;
  width: 100%;
}
.menu-footer-b ul.menu li {
  border: medium none;
  float: left;
  list-style: outside none none;
  margin: 0;
  padding: 0;
  text-align: left;
  width: 25%;
}
.menu-footer-b ul.menu li a {
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
}
.menu-footer-b ul.menu li a:hover {
  color: #c1282e;
}
.menu-footer-b ul.menu li ul {
  margin-top: 15px;
}
.menu-footer-b ul.menu li ul li {
  border: medium none;
  float: left;
  list-style: outside none none;
  margin: 0;
  padding: 0;
  text-align: left;
  width: 100%;
}
.menu-footer-b ul.menu li ul li a {
  text-transform: none;
  font-weight: normal;
}
.doi-tac {
  float: right;
  margin-right: 30px;
  width: 150px;
}
.doi-tac h2.title {
  font-size: 15px;
  margin-top: 0;
  color: #fff;
}
.slide-doi-tac {
  background: #232731 none repeat scroll 0 0;
  text-align: center;
}
.doi-tac-items {
  float: left;
  height: 110px;
  text-align: center;
  width: 100%;
  display: table;
}
.doi-tac-items img {
  max-height: 110px;
}
.tai-lieu-home {
  background: rgba(0, 0, 0, 0)
    url("http://www.phongthuytuongminh.com/sites/all/themes/idol_themes/images/bg-tailieupt.jpg")
    no-repeat scroll center center;
  float: left;
  height: 471px;
  margin-bottom: 30px;
  width: 100%;
}
.items-tailieu {
  float: left;
  width: 100%;
  color: #fff;
}
.items-tailieu h1.title {
  margin-top: 40px;
  text-transform: uppercase;
}
.items-tailieu h1.title a {
  color: #fff;
}
.items-tailieu h3.title a {
  color: #fff;
  display: inline-block;
  margin-top: 10px;
  font-size: 26px;
}
.items-tailieu .image {
  float: left;
  margin-right: 30px;
  margin-top: 30px;
  max-height: 350px;
  overflow: hidden;
  width: 400px;
}
.items-tailieu .text {
  float: right;
  width: 600px;
}
.items-tailieu .link-more {
  text-align: right;
}
.doi-tac-home-content {
  float: left;
  margin-bottom: 30px;
  text-align: center;
  width: 100%;
}
.doi-tac-home-content .linered {
  background: #c32f33 none repeat scroll 0 0;
  display: inline-block;
  height: 1px;
  width: 80px;
}
.doi-tac-home-content h2.title {
  color: #2c3946;
  font-size: 26px;
  font-weight: 700;
  line-height: 30px;
  margin: 15px 0 0;
  text-transform: uppercase;
}
.doi-tac-items-home {
  display: table;
  float: left;
  height: 110px;
  width: 100%;
}
.slide-doi-tac-home {
  float: left;
  width: 100%;
  margin-top: 10px;
}
.san-pham-home {
  float: left;
  margin-bottom: 30px;
  width: 100%;
}
.link-more-f {
  float: left;
  margin-top: 30px;
  text-align: center;
  width: 100%;
}
.link-more-f a {
  background: #c1282d none repeat scroll 0 0;
  border-radius: 4px;
  color: #fff;
  display: inline-block;
  font-size: 16px;
  padding: 6px 19px;
  text-transform: uppercase;
}
.link-more-f a:hover {
  color: #fff;
  opacity: 0.8;
}
.items-sanpham {
  border: 1px solid #ccc;
  float: none;
  height: 355px;
  margin: 0 auto;
  padding: 6px;
  text-align: center;
  width: 245px;
}
.items-sanpham h3.title {
  font-size: 15px;
  margin: 5px 0 0;
}
.items-sanpham .linered {
  display: inline-block;
}
.items-sanpham .price {
  font-weight: 600;
}
.owl-prev {
  color: #666;
  font-size: 50px;
  position: absolute;
  left: -20px;
  top: 40%;
}
.owl-next {
  color: #666;
  font-size: 50px;
  position: absolute;
  right: -20px;
  top: 40%;
}
.coltwo #content {
  float: right;
  width: 830px;
}
.left {
  float: left;
  width: 260px;
}
.left .block {
  float: left;
  width: 100%;
  margin-bottom: 20px;
}
h2.title.bgred.center {
  background: #c1282d none repeat scroll 0 0;
  color: #fff;
  font-size: 20px;
  margin-bottom: 0;
  margin-top: 0;
  padding: 10px 0;
  text-align: center;
}
.content.bg-xam {
  background: #ecedef none repeat scroll 0 0;
  float: left;
  width: 100%;
}
.left ul.menu,
.left ul.menu li {
  border: medium none;
  float: left;
  list-style: outside none none;
  margin: 0;
  padding: 0;
  text-align: left;
  width: 100%;
}
.left ul.menu li a {
  border-bottom: 1px solid #e0e0e0;
  color: #757575;
  float: left;
  padding: 15px;
  width: 230px;
}
.left ul.menu li a:hover,
.left ul.menu li a.active {
  background: #d8dade;
  color: #000;
}
.block.tuvanphongthuy {
  background: #ecedef none repeat scroll 0 0;
  padding: 10px;
  width: 240px;
}
.left h2.title {
  font-size: 20px;
  font-weight: 600;
  margin: 15px 0;
  text-align: center;
  text-transform: uppercase;
}
.left .items-tuvan {
  border-bottom: 1px solid #e0e0e0;
  float: none;
  margin: 0 auto 15px;
  padding-bottom: 15px;
  width: 100%;
}
.left .items-tuvan:last-child {
  border-bottom: none;
  margin: 0 auto 0;
  padding-bottom: 0;
}
#page-title-int-not {
  font-size: 24px;
  margin-top: 10px;
  text-transform: uppercase;
}
.items-san-pham {
  border: 1px solid #ccc;
  float: left;
  height: 355px;
  margin-bottom: 26px;
  margin-left: 26px;
  padding: 6px;
  text-align: center;
  width: 245px;
}
.items-san-pham.first {
  margin-left: 0;
}
.items-san-pham h3.title {
  font-size: 15px;
  margin: 5px 0 0;
}
.items-san-pham .linered {
  display: inline-block;
}
.items-san-pham .price {
  font-weight: 600;
}
.node-type-san-pham h1#page-title-int-not {
  display: none;
}
.node-left {
  border: 1px solid #ccc;
  float: left;
  padding: 10px;
  text-align: center;
  width: 505px;
}
.node-right {
  float: right;
  width: 280px;
}
.node-right .node-title {
  font-size: 26px;
  font-weight: 500;
}
.node-right .gia {
  color: #000;
  display: block;
  font-size: 24px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
}
.node-right .lead {
  color: #8f8f8f;
  float: left;
  font-size: 14px;
  margin-top: 10px;
  width: 100%;
}
.cart-add-form {
  float: left;
  width: 100%;
}
.cart-add-form label {
  display: inline-block;
  font-weight: normal;
  width: 75px;
}
.cart-add-form .form-text {
  border: 1px solid #ccc;
  color: #999;
  display: inline-block;
  text-align: center;
  width: 50px;
}
.cart-add-form .form-submit {
  background: #c1282d none repeat scroll 0 0;
  border: medium none;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  padding: 5px 20px;
  text-transform: uppercase;
  margin-top: 20px;
}
.node .body-field {
  float: left;
  margin-top: 20px;
  width: 100%;
}
h2.b-title {
  margin-bottom: 6px;
  text-transform: uppercase;
}
.node .icon-red {
  background: rgba(0, 0, 0, 0)
    url("http://www.phongthuytuongminh.com/sites/all/themes/idol_themes/images/icon-h2-red.png")
    no-repeat scroll 0 0;
}
.san-pham-khac {
  float: left;
  margin-top: 30px;
  width: 100%;
}
.items-news {
  float: left;
  margin-bottom: 30px;
  margin-left: 33px;
  width: 348px;
  height: 420px;
  overflow: hidden;
}
.items-news.first {
  margin-left: 0px;
}
.items-news .lead {
  color: #515151;
  text-align: justify;
}
.page-node #page-title-int {
  background: rgba(0, 0, 0, 0)
    url("http://www.phongthuytuongminh.com/sites/all/themes/idol_themes/images/icon-h2-red.png")
    no-repeat scroll 0 bottom;
  text-align: left;
}
.bailienquan .items-news .lead {
  display: none;
}
.bailienquan .items-news {
  height: 300px;
}
#messages {
  float: left;
  width: 100%;
  margin-top: 20px;
}
.view-empty {
  color: #999;
  display: block;
  font-size: 20px;
  padding: 50px 0;
  text-align: center;
}
.cau-hoi-tu-van {
  background: #c1282e none repeat scroll 0 0;
  color: #fff;
  float: left;
  width: 100%;
}
.form-cau-hoi {
  background: #c1282e none repeat scroll 0 0;
  float: left;
  width: 40%;
}
.noi-dung-form {
  float: right;
  margin-right: 30px;
  width: 360px;
}
.noi-dung-form label,
.grippie {
  display: none;
}
.form-text,
select,
textarea {
  border: 1px solid #ccc;
  min-width: 100px;
  padding: 6px;
  width: auto;
}
.noi-dung-form .form-text {
  width: 97%;
}
.noi-dung-form h2.title {
  margin: 30px 0 20px;
  text-align: center;
  color: #fff;
}
.noi-dung-form .form-submit {
  background: rgba(0, 0, 0, 0)
    url("http://www.phongthuytuongminh.com/sites/all/themes/idol_themes/images/btn-gui.jpg")
    no-repeat scroll center center;
  border: medium none;
  cursor: pointer;
  display: inline-block;
  height: 35px;
  margin-left: 10px;
  width: 119px;
  float: right;
}
.cau-hoi-thuong-gap {
  background: #303846 none repeat scroll 0 0;
  float: left;
  margin-left: 0;
  padding-left: 3%;
  padding-right: 3%;
  text-align: center;
  width: 54%;
}
.cau-hoi-thuong-gap h2.title {
  margin-top: 30px;
  text-align: center;
  margin-bottom: 0;
  color: #fff;
}
.cau-hoi-thuong-gap .linered {
  background: #c32f33 none repeat scroll 0 0;
  display: inline-block;
  height: 1px;
  width: 80px;
}
.list-faq {
  float: left;
  margin-top: 10px;
  text-align: justify;
  width: 100%;
}
.list-faq .icon {
  border: 3px solid #fff;
  border-radius: 50%;
  float: left;
  font-size: 38px;
  font-weight: 600;
  padding-bottom: 8px;
  padding-top: 8px;
  text-align: center;
  width: 40px;
}
.list-faq .text {
  border-bottom: 1px solid #222;
  float: right;
  font-size: 14px;
  padding-bottom: 10px;
  width: 88%;
}
.list-faq:last-child .text {
  border-bottom: none;
  padding-bottom: 10px;
}
.message-lits {
  float: left;
  margin-bottom: 20px;
  width: 100%;
}
.su-kien-phong-thuy-home {
  float: left;
  margin-bottom: 40px;
  width: 100%;
}
.items-sukien {
  float: left;
  margin-left: 5px;
  position: relative;
  line-height: 0;
}
.items-sukien.item-1,
.items-sukien.item-4,
.items-sukien.item-7 {
  margin-left: 0px;
}
.items-sukien .layout {
  background: rgba(0, 0, 0, 0)
    url("http://www.phongthuytuongminh.com/sites/all/themes/idol_themes/images/bg-sk.png")
    repeat-x scroll center bottom;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.items-sukien .info {
  bottom: 0;
  color: #fff;
  line-height: 24px;
  padding: 20px 3%;
  position: absolute;
  text-align: center;
  width: 94%;
}
.items-sukien .info h3.title {
  margin-bottom: 6px;
}
.items-sukien .info .date {
  color: #c1282d;
  font-size: 13px;
}
.lich-hoc-phong-thuy {
  float: left;
  width: 100%;
  margin-bottom: 40px;
}
.lich-hoc-phong-thuy h2.title {
  text-align: center;
  color: #2c3946;
}
.lich-hoc-phong-thuy h2.title .red {
  color: #c1282d;
}
.lich-hoc-pt {
  background: #ecedef none repeat scroll 0 0;
  float: left;
  width: 100%;
}
.lich-hoc {
  background: #e9e9e9 none repeat scroll 0 0;
  float: left;
  height: 470px;
  width: 302px;
}
.lich-hoc h3.title {
  margin-top: 30px;
  text-align: center;
}
.lich-hoc .scrollbar {
  float: left;
  height: 382px;
  margin-left: 20px;
  width: 262px;
  overflow: hidden;
}
.scrollbar > ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}
.scrollbar ul li {
  border-bottom: 1px dashed #e0e0e1;
  font-size: 15px;
  list-style: outside none none;
  margin: 0;
  padding: 12px 0;
}
.scrollbar ul li .icon {
  background: rgba(0, 0, 0, 0)
    url("http://www.phongthuytuongminh.com/sites/all/themes/idol_themes/images/icon-lh.png")
    no-repeat scroll 0 center;
  display: inline-block;
  height: 15px;
  margin-right: 5px;
  width: 19px;
  display: none;
}
.scrollbar ul li a {
  background: rgba(0, 0, 0, 0)
    url("http://www.phongthuytuongminh.com/sites/all/themes/idol_themes/images/icon-lh.png")
    no-repeat scroll 0 3px;
  display: inline-block;
  padding-left: 25px;
}
.scrollbar ul li strong.date-time {
  color: #2c3946;
  display: inline-block;
  margin-right: 15px;
}
.scrollbar ul li a:hover strong.date-time {
  color: #c1282d;
}
.khoa-hoc-slide-home .items-content-f .link > a:hover {
  background: #303846;
}
.khoa-hoc-slide-home {
  float: right;
  margin-bottom: 40px;
  position: relative;
  width: 750px;
}
.khoa-hoc-slide-home .ctab-wrap {
  float: left;
  width: 100%;
}
.khoa-hoc-slide-home .ctab-wrap .ctab {
  border: 1px solid #cfcfcf;
  float: left;
  margin-bottom: 30px;
  margin-top: 20px;
}
.khoa-hoc-slide-home .ctab-wrap .ctab a {
  border-right: 1px solid #cfcfcf;
  color: #929292;
  float: left;
  font-size: 17px;
  padding: 12px 25px;
}
.khoa-hoc-slide-home .ctab-wrap .ctab a:last-child {
  border-right: none;
}
.khoa-hoc-slide-home .ctab-wrap .ctab a.active {
  background: #fff;
  color: #2c3946;
  text-decoration: none;
}
.khoa-hoc-slide-home .items-content {
  background: #fff none repeat scroll 0 0;
  display: inline-block;
  float: none;
  margin: 0 auto;
  width: 233px;
}
.khoa-hoc-slide-home .owl-carousel .owl-item {
  text-align: center;
}
.khoa-hoc-slide-home .owl-carousel .owl-item .items-content {
  text-align: left;
}
.khoa-hoc-slide-home .items-content-info {
  color: #515151;
  display: block;
  height: 125px;
  padding: 0 15px;
}
.khoa-hoc-slide-home .items-content-info h3.title {
  height: 48px;
  overflow: hidden;
}
.khoa-hoc-slide-home .items-content-f {
  background: #c1282d none repeat scroll 0 0;
  color: #fff;
  display: block;
  float: left;
  font-size: 16px;
  font-weight: 600;
  margin-top: 30px;
  padding: 0;
  width: 100%;
}
.khoa-hoc-slide-home .items-content-f .link > a {
  color: #fff;
  float: right;
  padding: 10px;
  text-transform: uppercase;
}
.khoa-hoc-slide-home .items-content-f .price {
  float: left;
  padding: 10px;
}
.items-sanpham .image,
.items-tuvan .image,
.items-san-pham .image {
  position: relative;
}
.xem-detail {
  position: absolute;
  top: 35%;
  width: 100%;
  z-index: 999;
  display: none;
  text-align: center;
}
.items-sanpham:hover .xem-detail,
.items-tuvan:hover .xem-detail,
.items-san-pham:hover .xem-detail {
  display: block;
}
.xem-detail a.xem-chi-tiet {
  background: #c1282d none repeat scroll 0 0;
  border-radius: 4px;
  color: #fff;
  display: inline-block;
  padding: 5px 25px;
  text-transform: uppercase;
}
.xem-detail a.xem-chi-tiet:hover {
  background: #2c3946 none repeat scroll 0 0;
}
.link-all {
  font-size: 18px;
  position: absolute;
  right: 0;
  text-align: left;
  top: 34px;
  width: 130px;
}
.link-all a {
  color: #929292;
}
.view.view-lich-hoc-phong-thuy {
  float: left;
  margin-bottom: 30px;
  width: 100%;
}
.view.view-lich-hoc-phong-thuy table {
  width: 100%;
}
.view.view-lich-hoc-phong-thuy table th {
  background: #c1282e none repeat scroll 0 0;
  border: 1px solid #ccc;
  color: #fff;
  padding: 5px;
  font-size: 13px;
}
.view.view-lich-hoc-phong-thuy table td {
  font-size: 13px;
  border: 1px solid #ccc;
  padding: 5px;
}
table tr.odd {
  background-color: #e9e9e9;
}
table tr.even {
  background-color: #fff;
}
.node-type-hoc-phong-thuy #main-wrapper {
  margin-top: 0;
}
.node-type-hoc-phong-thuy #main-wrapper .contentner {
  width: 100%;
}
.khoa-hoc-out {
  margin: 0 auto;
  width: 1110px;
}
.khoa-hoc-int {
  float: left;
  width: 100%;
}
.left-node {
  background: #ff0008 none repeat scroll 0 0;
  color: #fff;
  float: left;
  width: 302px;
}
.left-node .content {
  display: block;
  padding: 20px;
}
.left-node .content h2.title {
  color: #fff;
  font-size: 20px;
  line-height: 30px;
  margin-top: 0;
}
.left-node .items-l {
  border-top: 1px solid #ff8084;
  display: block;
  float: left;
  padding: 15px 0;
  width: 100%;
}
.left-node .items-l .fa {
  /* position: relative;
  top:10px; */
  display: inline-block;
  float: left;
  font-size: 35px;
  margin-right: 10px;
  text-align: center;
  width: 35px;
}
.left-node .items-l span {
  display: inline-block;
  float: left;
  margin-top: 5px;
}
.left-node .link > a {
  background: #303846 none repeat scroll 0 0;
  color: #fff;
  float: left;
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 15px;
  padding: 20px 0;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
}
.right-node {
  float: right;
  width: 780px;
}
.right-node h2.title {
  background: rgba(0, 0, 0, 0)
    url("http://www.phongthuytuongminh.com/sites/all/themes/idol_themes/images/icon-h2-red.png")
    no-repeat scroll center bottom;
  float: left;
  margin-bottom: 10px;
  margin-left: 50px;
  margin-top: 36px;
  padding-bottom: 26px;
}
.right-node .content {
  float: left;
  width: 100%;
}
.right-node .field.field-name-body {
  float: left;
  text-align: justify;
  width: 420px;
}
.right-node .field.field-name-field-image {
  float: right;
  width: 330px;
}
.chuong-trinh-hoc {
  background: #ecedef none repeat scroll 0 0;
  float: left;
  margin-top: 30px;
  padding: 20px 0;
  width: 100%;
  padding-bottom: 0;
}
.chuong-trinh-hoc .khoa-hoc-int {
  background: rgba(0, 0, 0, 0)
    url("http://www.phongthuytuongminh.com/sites/all/themes/idol_themes/images/bg-intro.png")
    no-repeat scroll 0 bottom;
}
.node .chuong-trinh-hoc .icon-red {
  background: rgba(0, 0, 0, 0)
    url("http://www.phongthuytuongminh.com/sites/all/themes/idol_themes/images/icon-h2-red.png")
    no-repeat scroll center 0;
}
.chuong-trinh-hoc .link {
  float: left;
  width: 100%;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 30px;
}
.chuong-trinh-hoc .link > a {
  background: #ff0008 none repeat scroll 0 0;
  color: #fff;
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
  padding: 15px 30px;
  text-transform: uppercase;
}
.giang-vien-chuong-trinh-hoc {
  background: #ecedef none repeat scroll 0 0;
  float: left;
  margin-bottom: 0px;
  position: relative;
  width: 100%;
}
.khoa-hoc-khac.bailienquan {
  float: left;
  margin-top: 10px;
  width: 100%;
}
.khoa-hoc-khac.bailienquan h2.b-title {
  text-align: center;
}
.pager.pager-load-more li a {
  background: #c1282e none repeat scroll 0 0;
  color: #fff;
  display: inline-block;
  margin-bottom: 40px;
  padding: 5px 30px;
  text-transform: uppercase;
}
.khoa-hoc-khac.bailienquan .icon-red {
  background: rgba(0, 0, 0, 0)
    url("http://www.phongthuytuongminh.com/sites/all/themes/idol_themes/images/icon-h2-red.png")
    no-repeat scroll center 0;
}
.node-type-hoc-phong-thuy
  #main-wrapper
  .giang-vien-chuong-trinh-hoc
  .contentner {
  width: 1110px;
}
.giang-vien-chuong-trinh-hoc .master-home {
  margin: 0;
}
.body-giang-vien {
  float: left;
  margin-top: 10px;
  text-align: justify;
  width: 100%;
}
.scroll-content {
  display: block;
  padding-right: 20px;
}
.node .giang-vien-chuong-trinh-hoc .icon-red {
  background: rgba(0, 0, 0, 0)
    url("http://www.phongthuytuongminh.com/sites/all/themes/idol_themes/images/icon-h2-red.png")
    no-repeat scroll center 0;
  position: absolute;
  z-index: 999;
}
.items-khoa-hoc {
  background: #ecedef;
  float: left;
  height: 388px;
  margin-bottom: 30px;
  margin-left: 33px;
  overflow: hidden;
  width: 348px;
}
.items-khoa-hoc.first {
  margin-left: 0;
}
.items-khoa-hoc .img {
  float: left;
  height: 222px;
  position: relative;
  width: 100%;
}
.items-khoa-hoc .price-link {
  bottom: 0;
  position: absolute;
}
.items-khoa-hoc .price-link .price {
  background: #c1282d none repeat scroll 0 0;
  color: #fff;
  float: left;
  font-weight: 600;
  padding: 8px 15px;
  width: auto;
}
.items-khoa-hoc .price-link a {
  background: #c1282d none repeat scroll 0 0;
  color: #fff;
  float: left;
  font-weight: 600;
  padding: 8px 15px;
  width: auto;
  text-transform: uppercase;
}
.items-khoa-hoc .price-link a:hover {
  background: #303846 none repeat scroll 0 0;
}
.items-khoa-hoc .info {
  float: left;
  padding: 15px;
  width: 318px;
}
.items-khoa-hoc .info h3.title {
  float: left;
  margin: 0;
  width: 155px;
}
.items-khoa-hoc .info .right-info {
  color: #515151;
  float: right;
  width: 160px;
}
.form-one {
  border: 1px solid #999;
  border-radius: 6px;
  box-shadow: 0 0 5px 0 #999;
  display: table;
  font-size: 13px;
  margin: 10px auto 40px;
  padding: 20px;
  width: 600px;
}
.form-one .form-text,
.form-one select,
.form-one textarea {
  border: 1px solid #ccc;
  padding: 6px;
  max-width: 100%;
  width: auto;
  min-width: 100px;
}
.form-one .form-submit {
  background: #c1282d none repeat scroll 0 0;
  border: medium none;
  color: #fff;
  cursor: pointer;
  float: left;
  font-weight: 600;
  padding: 8px 15px;
  text-transform: uppercase;
  width: auto;
}
.form-one h3 {
  text-transform: uppercase;
}
.form-one label {
  color: #515151;
}
select {
  background: #fff
    url(http://www.phongthuytuongminh.com/sites/all/themes/idol_themes/images/select.png)
    no-repeat right;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  text-indent: 0.01px;
  text-overflow: "";
  padding-right: 25px;
}
select::-ms-expand {
  display: none;
}
.form-cart {
  float: left;
  margin-bottom: 40px;
  width: 100%;
}
.form-cart table {
  border: 1px solid #ccc;
  width: 100%;
}
.form-cart table th,
.form-cart table td {
  padding: 6px;
}
.form-cart table tr {
  border: 1px solid #ccc;
}
.form-cart table tr input.form-text {
  border: 1px solid #ccc;
  width: 40px;
  text-align: center;
}
.form-cart table .colall {
  text-align: right;
}
.form-cart .form-action {
  float: left;
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: right;
  width: 100%;
}
.form-cart .form-action .form-submit {
  background: #c1282d none repeat scroll 0 0;
  border: medium none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  margin-left: 20px;
  padding: 5px 10px;
}
.empty {
  color: #515151;
  margin-bottom: 40px;
  text-align: center;
}
.empty a {
  background: #c1282d none repeat scroll 0 0;
  color: #fff;
  display: inline-block;
  font-weight: 600;
  margin-top: 10px;
  padding: 4px 15px;
  text-transform: uppercase;
  width: auto;
}
.empty a:hover {
  background: #c1282d none repeat scroll 0 0;
  color: #fff;
}
.form-don-hang {
  border: 1px solid #ccc;
  display: block;
  padding: 20px;
}
.form-cart .left-form {
  float: left;
  font-size: 13px;
  width: 45%;
}
.form-cart .form-right {
  background: #eee none repeat scroll 0 0;
  border: 1px solid #ccc;
  float: right;
  padding: 10px 2%;
  width: 48%;
}
.form-cart .form-right label {
  font-weight: normal;
}
.form-cart .form-right .form-submit {
  background: #c1282d none repeat scroll 0 0;
  border: medium none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  padding: 5px 10px;
}
.menu-mobile {
  display: none;
}
.mobile-over {
  background: rgba(0, 0, 0, 0.7) none repeat scroll 0 0;
  display: none;
  height: 100%;
  position: fixed;
  width: 100%;
  z-index: 9999;
}
.menu-mobile-site {
  background: #eee none repeat scroll 0 0;
  display: none;
  float: left;
  height: 100%;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 250px;
  z-index: 999999;
}
.header-menu {
  background: #ccc none repeat scroll 0 0;
  float: left;
  width: 100%;
}
.header-menu a {
  color: #666;
  float: right;
  font-size: 29px;
  line-height: 18px;
  margin: 9px;
}
.menu-mobile-site > ul {
  float: left;
  list-style: outside none none;
  margin: 0;
  padding: 0;
  width: 100%;
}
.menu-mobile-site > ul li {
  float: left;
  list-style: outside none none;
  margin: 0;
  padding: 5px 0;
  width: 100%;
  border-bottom: 1px solid #e4e4e4;
}
.menu-mobile-site > ul li ul li:last-child {
  border-bottom: none;
}
.menu-mobile-site ul li ul {
  float: left;
  list-style: outside none none;
  margin: 0;
  padding: 0;
  width: 100%;
  background: #fff;
  display: none;
}
.menu-mobile-site > ul li a {
  font-size: 13px;
  font-weight: 600;
  padding-left: 15px;
  text-transform: uppercase;
}
.menu-mobile-site > ul li ul li a {
  text-transform: none;
  font-weight: 500;
}
.menu-mobile-site > ul li .fa {
  float: right;
  font-size: 20px;
}
.menu-mobile-site > ul li .fa {
  float: right;
  font-size: 20px;
  margin-right: 6px;
}
.menu-mobile-site > ul li .fa.fa-angle-up {
  display: none;
}
#_quick-link {
  background: none;
  left: -200px;
  padding: 10px 2px;
  position: fixed;
  top: calc(20% - 78px);
  width: 130px;
  z-index: 999;
}
#_quick-link > div {
  float: left;
  text-align: center;
  width: 100%;
  margin-bottom: 3px;
}
#_quick-link .text {
  float: left;
  font-size: 13px;
  width: 100%;
}
#_quick-link .bg {
  background: #b2b2b2 none repeat scroll 0 0;
  border-radius: 50%;
  display: inline-block;
  height: 54px;
  padding-top: 8px;
  width: 62px;
}
#_quick-link a:hover .bg {
  background: #c1282d none repeat scroll 0 0;
}
.view-more {
  float: left;
  margin: 10px 0;
  width: 100%;
}
.view-more > a {
  color: #fff;
  text-transform: uppercase;
}
.contact-map {
  float: right;
  width: 50%;
}
.contact-form {
  float: left;
  width: 40%;
}
.contact-form label {
  display: none;
}
.contact-form .form-submit {
  background: #c1282e none repeat scroll 0 0;
  border: medium none;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 20px;
  padding: 6px 20px;
}
.view.view-lich-hoc th {
  background: #d72136 none repeat scroll 0 0;
  border-right: 1px solid white;
  color: white;
  height: 37px;
  text-align: center;
}
.view.view-lich-hoc tbody tr.odd {
  background: #ddd none repeat scroll 0 0;
}
.view.view-lich-hoc tbody tr {
  border-bottom: 2px solid white;
}
.view.view-lich-hoc tbody tr.even {
  background: #f2f2f2 none repeat scroll 0 0;
}
.view.view-lich-hoc tr td {
  border-right: 1px solid white;
  font-size: 13px;
  height: 37px;
  text-align: center;
}
.view.view-lich-hoc tbody tr td:first-child {
  background: #153980 none repeat scroll 0 0;
  color: #fff;
}
.view.view-lich-hoc table {
  border-collapse: collapse;
  width: 100%;
}
.view.view-lich-hoc {
  float: left;
  margin-bottom: 30px;
  width: 100%;
}
.teaser-node {
  float: left;
  margin-bottom: 20px;
  width: 100%;
}
.teaser-node .image {
  float: left;
  margin-right: 20px;
  width: 160px;
}
.teaser-node .node-title {
  margin-top: 0;
}

div.captcha {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@media (max-width: 1024px) {
  .contentner {
    margin: 0 auto;
    width: 1000px;
  }
  .khoa-hoc-slide-home {
    float: right;
    margin-bottom: 40px;
    position: relative;
    width: 688px;
  }
  .khoa-hoc-slide-home .ctab-wrap .ctab a {
    padding: 12px 24px;
  }
  hoc-slide-home .items-content {
    width: 220px;
  }
  .items-sanpham {
    width: 230px;
  }
  .items-tailieu .text {
    width: 566px;
  }
  .menu-footer-b {
    float: left;
    width: 812px;
  }
  .items-tuvan {
    width: 250px;
  }
  .items-news {
    margin-left: 26px;
    width: 315px;
  }
  .cau-hoi-thuong-gap {
    background: #303846 none repeat scroll 0 0;
    float: left;
    margin-left: 0;
    padding-left: 3%;
    padding-right: 3%;
    text-align: center;
    width: 54%;
  }
  .items-khoa-hoc {
    margin-left: 16px;
    width: 322px;
  }
  .items-khoa-hoc .img {
    height: 206px;
  }
}
@media (max-width: 800px) {
  .contact-map {
    float: right;
    width: 100%;
  }
  .contact-form {
    float: left;
    width: 100%;
  }
  body {
    overflow-x: hidden;
  }
  .contentner {
    margin: 0 auto;
    width: 98%;
  }
  .menu-site {
    display: none;
  }
  .menu-mobile {
    color: #fff;
    display: block;
    float: right;
    font-size: 26px;
    margin-right: 16px;
    margin-top: 10px;
  }
  .header {
    background: rgba(0, 0, 0, 0.9) none repeat scroll 0 0;
    float: left;
    left: 0;
    position: relative;
    top: 0;
    width: 100%;
    z-index: 999;
  }
  .logo-site {
    float: left;
    width: 100%;
  }
  .logo-site .ads-show {
    float: left;
  }
  .logo-site .ads-show img {
    float: left;
    height: 60px;
    width: auto;
  }
  .logo-on {
    float: left;
    height: auto;
    position: relative;
    width: 100%;
  }
  .right-h {
    background: #eee none repeat scroll 0 0;
    float: right;
    margin-top: 0;
    text-align: center;
    width: 100%;
  }
  .hotline {
    color: #666;
    display: inline-block;
    float: none;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    margin-top: 0;
  }
  .hotline a {
    color: #666;
  }
  .search-form {
    display: inline-block;
    float: none;
    margin-bottom: 0;
    margin-left: 10px;
  }
  .hotline .fa.fa-phone {
    border-right: medium none;
    float: left;
    font-size: 18px;
    margin-right: 0;
    padding-right: 6px;
  }
  .btn-show-search {
    border: medium none;
    color: #666;
    cursor: pointer;
    float: right;
    font-size: 15px;
    padding: 4px 5px;
    display: none;
  }
  form.form-tim-kiem {
    background: #fff none repeat scroll 0 0;
    display: inline-block;
    float: none;
    margin-bottom: 5px;
    margin-top: 5px;
  }
  .header .contentner {
    width: 100%;
  }
  .ads-slide-show .body-only .content-body {
    margin: 0 auto;
    width: 98%;
  }
  .ads-slide-show .body-only {
    color: #fff;
    font-size: 16px;
  }
  .link-page {
    border: 1px solid #ccc;
    display: inline-block;
    float: none;
    font-size: 13px;
    margin-bottom: 20px;
    padding: 3px 10px;
    position: relative;
    right: 0;
    top: 0;
    width: auto;
  }
  .lich-hoc {
    background: #e9e9e9 none repeat scroll 0 0;
    float: left;
    height: 470px;
    width: 100%;
  }
  .khoa-hoc-slide-home {
    float: right;
    margin-bottom: 40px;
    position: relative;
    width: 100%;
  }
  .khoa-hoc-slide-home .ctab-wrap .ctab {
    width: 100%;
  }
  .khoa-hoc-slide-home .ctab-wrap .ctab a {
    font-size: 16px;
    padding: 8px 20px;
  }
  .khoa-hoc-slide-home .link-all {
    display: none;
  }
  .master-home {
    background: #fff none repeat scroll 0 0;
    float: left;
    height: auto;
    margin-bottom: 30px;
    width: 100%;
  }
  .block-master-home .ads-items .image {
    bottom: 0;
    position: relative;
    text-align: center;
    width: 100%;
    padding: 15px 0;
  }
  .block-master-home .ads-items .image img {
    display: inline-block;
    max-width: 100%;
  }
  .block-master-home .ads-items .inform {
    color: #666;
    display: table;
    float: right;
    height: auto;
    text-align: center;
    width: 100%;
  }
  .block-master-home .ads-items {
    float: left;
    height: auto;
    position: relative;
    width: 100%;
  }
  .linered {
    background: #c32f33 none repeat scroll 0 0;
    display: inline-block;
    height: 1px;
    width: 80px;
  }
  .block-master-home .link-more {
    text-align: center;
  }
  .block-master-home .ads-items .inform h1.title {
    color: #555;
    text-transform: uppercase;
  }
  h2.title {
    font-size: 22px;
  }
  .tai-lieu-home {
    background: #fff none repeat scroll 0 0;
    float: left;
    height: auto;
    margin-bottom: 30px;
    text-align: center;
    width: 100%;
  }
  .items-tailieu {
    color: #666;
    float: left;
    width: 100%;
  }
  .items-tailieu .link-more {
    text-align: center;
  }
  .items-tailieu h1.title a {
    color: #555;
  }
  .items-tailieu .image {
    float: left;
    margin-right: 0;
    margin-top: 30px;
    width: 100%;
  }
  .logo-footer {
    float: left;
    text-align: center;
    width: 100%;
  }
  .footer-site .address {
    float: left;
    margin-left: 0;
    margin-top: 6px;
    text-align: center;
    width: 100%;
  }
  .social {
    float: right;
    margin-top: 16px;
    text-align: center;
    width: 100%;
  }
  .menu-footer-b {
    float: left;
    text-align: center;
    width: 100%;
  }
  .menu-footer-b ul.menu li {
    text-align: center;
    width: 100%;
  }
  .menu-footer-b ul.menu li ul li {
    text-align: center;
  }
  .doi-tac {
    float: right;
    text-align: center;
    width: 100%;
  }
  .owl-next {
    right: 0px;
  }
  .owl-prev {
    left: 0px;
  }
  .simplenews-subscribe .form-submit {
    margin-left: 0;
    margin-top: 10px;
  }
  .simplenews-subscribe .form-item.form-type-textfield.form-item-mail {
    display: inline-block;
    float: left;
    margin: 0;
    width: 100%;
  }
  .simplenews-subscribe
    .form-item.form-type-textfield.form-item-mail
    input.form-text {
    background: #fff none repeat scroll 0 0;
    border: medium none;
    display: inline-block;
    margin: 0;
    padding: 9px 0;
    text-align: center;
    width: 100%;
  }
  .news-timeline {
    float: left;
    text-align: center;
    width: 100%;
  }
  .items-news {
    display: inline-block;
    float: none;
    margin-left: auto;
    margin-right: auto;
  }
  #breadcrumb {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    bottom: 0;
    color: #666;
    height: auto;
    position: relative;
    width: 100%;
    z-index: 9999;
  }
  h1#page-title {
    display: none;
  }
  .breadcrumb {
    float: left;
    font-size: 11px;
    margin-top: 10px;
    padding-bottom: 0;
    text-transform: uppercase;
    width: 100%;
    text-align: center;
  }
  .breadcrumb a {
    color: #666;
  }
  h2.b-title {
    text-align: center;
  }
  .node .icon-red {
    background: rgba(0, 0, 0, 0)
      url("http://www.phongthuytuongminh.com/sites/all/themes/idol_themes/images/icon-h2-red.png")
      no-repeat scroll center 0;
  }
  .left {
    display: none;
  }
  .coltwo #content {
    float: right;
    width: 100%;
  }
  .items-san-pham {
    border: 1px solid #ccc;
    display: inline-block;
    float: none;
    height: 355px;
    margin-bottom: 26px;
    margin-left: 13px;
    margin-right: 13px;
    padding: 6px;
    text-align: center;
    width: 245px;
  }
  .items-san-pham.first {
    margin-left: 13px;
  }
  #page-title-int-not {
    font-size: 24px;
    margin-top: 10px;
    text-align: center;
    text-transform: uppercase;
  }
  .node-left {
    border: none;
    float: left;
    line-height: 0;
    padding: 0;
    text-align: center;
    width: 100%;
  }
  .node-right {
    background: #eee none repeat scroll 0 0;
    float: right;
    margin-top: 20px;
    padding-bottom: 15px;
    text-align: center;
    width: 100%;
  }
}

body {
  font-size: 14px;
  padding: 0;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}
ul {
  list-style-type: none;
  padding: 0;
}
p {
  padding: 0;
  margin: 0;
}
a {
  color: #333;
  text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}
section#second {
  margin-top: 60px;
  padding: 0 32px;
  height: 257px;
}

section#third {
  /* margin-top: 60px; */
  padding: 32px;
  height: 273px;
}
#first {
  position: relative;
}
.p-description {
  top: 10px;
  position: absolute;
  left: 0;
  right: 0;
  font-size: 30px;
  color: #fff;
  text-transform: uppercase;
}

.custom-year {
  font-weight: 700;
  font-size: 50px;
  position: relative;
  top: -4px;
  padding: 0 10px;
}
.p-date p {
  font-size: 430px;
  font-weight: 700;
  margin: 0;
  color: #e4007b;
  -webkit-text-stroke-width: 4px;
  -webkit-text-stroke-color: #fff;

}
.p-flex {
  display: flex;
  justify-content: space-between;
  height: 300px;
}
.container {
  margin: 0 auto;
  /* border:1px solid #676767; */
  max-width: 850px;
  /* height: 1200px; */
  position: relative;
}
.col-1 {
  width: 30%;
  margin-top: -20px;
}
.block-header h3.title {
  text-transform: uppercase;
  font-size: 30px;
  border-bottom: 1px solid #333;
  font-weight: 700;
  height: 45px;
}
.flex ul {
  display: flex;
  margin: 0;
  text-align: center;
}
.flex ul li {
  flex: 1;
}
.custom-flexbox-1 {
  text-align: right;
  position: relative;
  right: -80px;
  padding-top: 14px;
  font-weight: 700;
  /* text-shadow: 0px 0px 5px #e4007b; */
  white-space: nowrap;
}
.bg-loading {
  position: fixed;
  z-index: 999;
  overflow: show;
  opacity: 0.9;
  margin: auto;
  background: #fff;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.loading:after {
  /* content: ''; */
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}
.bg-loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #333;
}
.custom-flexbox-2 {
  text-align: left;
  position: relative;
  font-weight: 700;
  right: 80px;
  padding-top: 14px;
  white-space: nowrap;
}

.p-absolute {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
}
.right-section3-chinese{
  font-size: 33px;
}
.p-color-blue {
  color: #2e2ec1 !important;
}
.p-color-black {
  color: #333 !important;
}
.content {
  font-size: 22px;
}
.wrapper {
  /* margin: 0 10px; */
}
.block-header {
  color: #e4007b;
}
.content ul li a {
  color: #e4007b;
}
.tiet-khi-chinese{
  font-size: 16px;
}
.tiet-khi-2-chinese{
  font-size: 22px;
}
.p-ti-khi {
  color: #333;
  font-size: 17px;
  padding: 5px 0;
  border-bottom: 1px solid #333;
  height: 50px;
}
.p-ti-khi-2 {
  color: #9b0023;
  font-size: 17px;
  padding: 10px 0;
  border-bottom: 1px solid #333;
  height: 45px;
}
.content ul {
  margin: 0;
  padding: 10px 0;
  border-bottom: 1px solid #333;
  height: 140px;
}
.col-3 {
  width: 15%;
}
.col-5 {
  width: 85%;
  padding: 0 0px;
  /* height: 420px; */
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.content-third ul {
  padding: 0px 0;
  margin: 0;
  border-top: unset;
  border: 2px solid red;
  text-align: center;
  line-height: 1.5;
  height: 130px;
  display: table-cell;
    vertical-align: middle;
width: 200px;
}
.content-third div {
  text-align: center;
}
.content-third a {
  color: red;
  font-weight: 700;
}
.block-header-third p {
  /* background: red; */
  /* width: 94px; */
  color: #fff;
  font-size: 12px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  text-align: center;
   padding-left:  3px;
   padding-right: 3px;
  /*padding-top: 5px; */
  text-transform: uppercase;
}
.block-header-third h4 {
  /* background: red; */
  /* width: 94px; */
  color: #fff;
  font-size: 12px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  text-align: center;
  /* padding: 3px;
  padding-top: 5px; */
  text-transform: uppercase;
}
.mt-5 {
  margin-top: 5px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 10px;
}
.block-header-third.mt-15 h3 {
  background: gray;
}
.block-header-third.mt-15 ul {
  border: 2px solid gray;
}
.block-header-third.mt-15 ul li a {
  color: gray;
}
.content-middle {
  text-align: center;
  line-height: 1.5;
  text-transform: capitalize;
  /* font-weight: 700; */
  padding: 10px;
}
.content-middle p {
  font-size: 15px;
}
.text-transform {
  text-transform: uppercase;
  font-weight: 700;
}
.footer {
  /* margin-top: 10px; */
  /* display: flex; */
  justify-content: space-between;
  /* background: #ff750026; */
  padding: 10px 0px;
  /* position: absolute; */
  /* bottom: 0; */
  /* width: 100%; */
  /* left: 0; */
}
.footer-title {
  text-align: center;
}
.footer-title .text-transform {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 700;
  top: 22px;
  position: relative;
}
p.footer-custom {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  width: 500px;
  margin: 0 auto;
  font-size: 24px;
  padding: 2px 0;
  text-transform: uppercase;
  color: red;
  font-weight: 700;
}
.custom-middle .title {
  background: red;
  color: yellow;
  padding: 5px;
  border-radius: 50px;
  width: 80%;
  margin:auto;
}

.custom-height {
  position: relative;
  top: -10px;
}
.size-125 {
  font-size: 125px;
  line-height: 125px;

}
.size-60 {
  position: relative;
  top: -10px;
  font-size: 60px;
  font-weight: 700;
}
